export default function horizontalScrollCreator(el) {
    const tallOuterContainer = el.querySelector(".tallOuterContainer");
    const stickyInnerContainer = el.querySelector(".stickyInnerContainer");
    const horizontalTranslateContainer = el.querySelector(
        ".horizontalTranslateContainer"
    );

    let isMobile = false;
    let containerRef = stickyInnerContainer;
    let objectRef = horizontalTranslateContainer;

    function calcDynamicHeight(objectWidth) {
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        const vhCSSVariable = window.innerHeight * 0.01;
        document.documentElement.style.setProperty(
            "--vh",
            `${vhCSSVariable}px`
        );
        return objectWidth - vw + vh + 48;
    }

    function handleDynamicHeight(ref) {
        if (isMobile) {
            const objectWidth = ref.scrollWidth;
            const dynamicHeight = calcDynamicHeight(objectWidth);
            tallOuterContainer.style.height = `${dynamicHeight}px`;
        }
    }

    function applyScrollListener(ref) {
        window.addEventListener("scroll", () => {
            if (isMobile) {
                const offsetTop = -ref.offsetTop;
                horizontalTranslateContainer.style.transform = `translateX(${offsetTop}px)`;
            }
        });
    }

    function resizeHandler() {
        handleDynamicHeight(objectRef);
    }

    function WidthChange(mq) {
        if (mq.matches) {
            isMobile = true;
        } else {
            isMobile = false;
            tallOuterContainer.style.removeProperty("height");
            horizontalTranslateContainer.style.removeProperty("transform");
        }
    }

    return {
        initialize() {
            if (matchMedia) {
                const mq = window.matchMedia("(max-width: 920px)");
                mq.addListener(WidthChange);
                WidthChange(mq);
            }

            handleDynamicHeight(objectRef);
            window.addEventListener("resize", resizeHandler);
            applyScrollListener(containerRef);
        }
    };
}
