import createFrameCapture from './frame-capturer';
import QrScannerWorker from './scanner.worker';

function createWorkerPool(count, foundCallback) {
    const workers = Array.from({ length: count }).map(createWorker);
    const freeWorkers = workers.slice(0);

    function createWorker() {
        const worker = new QrScannerWorker();
        worker.addEventListener(
            'message',
            e => {
                const action = e.data;

                if (action.type === 'SCAN_RESULT') {
                    foundCallback(action.payload.code);
                    freeWorkers.push(worker);
                }
            },
            false
        );
        return worker;
    }

    return {
        postMessage(message, transferable) {
            freeWorkers.forEach(worker => {
                if(freeWorkers.length === 1) {
                    worker.postMessage(message, transferable);
                } else {
                    worker.postMessage(message);
                }
            });
            freeWorkers.length = 0;
        },
        destroy() {
            workers.forEach(worker => worker.terminate());
        }
    }
}

function createCodeNormalizer() {
    let reportedCodes = {};
    return {
        report(code) {
            reportedCodes[code] = typeof reportedCodes[code] !== 'undefined' ? reportedCodes[code]+1 : 1;
        },
        retrieveNormalizedCode() {
            let mostOftenOccuringCode = null;
            Object.keys(reportedCodes).reduce((max, code) => {
                if(reportedCodes[code] > max) {
                    mostOftenOccuringCode = code;
                    return reportedCodes[code];
                }
            }, 1);
            return mostOftenOccuringCode;
        }
    }
}

export default function createScanner(
    videoElement,
    foundCallback,
) {
    let workerCount = 1;
    let found = false;

    const codeNormalizer = createCodeNormalizer();

    const pool = createWorkerPool(workerCount, (code) => {
        if(found) {
            return;
        }

        if(code) {
            codeNormalizer.report(code);
            const normalizedCode = codeNormalizer.retrieveNormalizedCode();
            if(normalizedCode) {
                found = true;
                foundCallback(normalizedCode);
            }
        }
        captureFrame();
    });

    const captureFrame = createFrameCapture(
        videoElement,
        (img, width, height) => {
            const action = {
                type: 'SCAN_REQUEST',
                payload: {
                    bytes: img.buffer,
                    width,
                    height,
                },
            };

            pool.postMessage(action, [img.buffer]);
        }
    );

    captureFrame();

    return () => {
        pool.destroy();
    };
}
