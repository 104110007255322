export default function barcodeFormCreator(el) {
    el.addEventListener('submit', (e) => {
        e.preventDefault();
        el.dispatchEvent(
            new CustomEvent('submitCode', {
                detail: {
                    code: el.elements.code.value
                }
            })
        );
    });

    return {
        reset() {
            el.reset();
        },
        setCode(code) {
            el.elements.code.value = code;
        },
        getCode() {
            return el.elements.code.value;
        }
    }
}
