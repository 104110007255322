import modalCreator from '@/components/modal/modal';
import { createControllerList } from '@/utils/create-controllers';
import { createScanner, getCameraStream } from '@/scanner';

export default function barcodeScanModalCreator(el) {
    const [modal] = createControllerList(el, modalCreator);
    const video = el.querySelector('video');
    let stopScanner;

    el.addEventListener('close', () => {
        if (video.srcObject) {
            video.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }

        if(stopScanner) {
            stopScanner();
        }
    });

    return {
        show() {
            getCameraStream()
                .then((stream) => {
                    video.srcObject = stream;
                    video.play();
                    stopScanner = createScanner(video, (code) => {
                        if(code) {
                            el.dispatchEvent(new CustomEvent('scan', {
                                detail: {
                                    code,
                                }
                            }));
                        }
                    });
                })
                .catch(() => {
                    el.dispatchEvent(new CustomEvent('rejected'));
                })

            modal.show();
        },

        close: modal.close,
    }
}
