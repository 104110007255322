
export default function scanCode(code) {
    document.body.classList.add('loading');

    function removeLoadingClass() {
        document.body.classList.remove('loading');
    }

    return import(/* webpackChunkName: "medicines" */'medicines.json')
        .then((medicines) => {
            if(!medicines[code]) {
                throw new Error(`Medicine with code ${code} not found`);
            }
            return {
                code,
                metadata: medicines[code],
                redirect: () => location.href = `/medicine/${code}`,
            }
        })
        .finally(removeLoadingClass);
}
