const getUserMedia = (function determineGetUserMedia() {
    // return the modern api which uses promises
    if (navigator.mediaDevices) {
        return navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);
    }

    // return the deprecated api
    const getUserMediaWithCallbacks =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

    return (constraints) => {
        return new Promise((resolve, reject) => {
            if (!getUserMediaWithCallbacks) {
                reject(new Error('Get user media is unsupported'));
            }

            getUserMediaWithCallbacks(constraints, resolve, reject);
        });
    };
})();

export default function getCameraStream() {
    return getUserMedia({
        video: {
            facingMode: 'environment',
            frameRate: 30,
            width: 640,
            height: 480,
        },
        audio: false,
    });
}
