import './index.css';

import preventBfCache from '@/utils/prevent-bf-cache';
import browseHappy from '@/components/browse-happy/browse-happy';
import initErrorLogging from '@/utils/init-error-logging';

preventBfCache();
browseHappy();
initErrorLogging();

if(process.env.APP_ENV === 'kiosk') {
    require('./index-kiosk').init();
} else {
    require('./index-web').init();
}
