import { createControllerList, createControllerMap } from '@/utils/create-controllers';
import barcodeFormCreator from '@/components/barcode-form/barcode-form';
import barcodeScanModalCreator from '@/components/barcode-scan-modal/barcode-scan-modal';
import horizontalScrollCreator from '@/components/horizontal-scroll/horizontal-scroll';
import installModalClickEvents from '@/utils/install-modal-click-events';
import modalCreator from '@/components/modal/modal';
import scanCode from '@/utils/scan-code';

export function init() {
    const modals = createControllerMap(document, '.modal', modalCreator);
    const observer = new IntersectionObserver(handleEntry, {threshold: [1]});
    const [barcodeFormMain, barcodeFormModal] = createControllerList(document, '[data-barcode-form]', barcodeFormCreator);
    const [barcodeScanModal] = createControllerList(document, '#barcode-scan-modal', barcodeScanModalCreator);
    const [horizontalScroll] = createControllerList(document, '.horizontalSection', horizontalScrollCreator)

    for (const el of document.querySelectorAll('.homepage-why__bullet-list li')) {
        observer.observe(el);
    }

    function handleEntry(entries) {
        entries.forEach(entry => {
            entry.isIntersecting
                ? entry.target.classList.add('visible')
                : entry.target.classList.remove('visible');
        });
    }

    function handleCode(e) {
        const code = e.detail.code;
        scanCode(code)
        .then(({ redirect }) => {
            redirect();
        })
        .catch(() => {
            barcodeScanModal.close();
            
            /*
            Show the error outside the modal, but keep the code
            */
           if(barcodeFormModal.getCode()) {
               barcodeFormMain.setCode( barcodeFormModal.getCode() );
            }
            
            barcodeFormModal.reset();
            
            document.body.dataset.status = 'wrong-code';
            document.querySelector('.homepage-header').style.removeProperty('background-image');
        });
    }
    
    barcodeScanModal.addEventListener('scan', handleCode);
    barcodeFormMain.addEventListener('submitCode', handleCode);
    barcodeFormModal.addEventListener('submitCode', handleCode);
    
    barcodeScanModal.addEventListener('rejected', () => {
        document.body.dataset.status = 'no-camera';
        document.querySelector('.homepage-header').style.removeProperty('background-image');
        barcodeScanModal.close();
    });
    
    Array.from(document.querySelectorAll('[data-start-scan]')).forEach(button => {
        button.addEventListener('click', () => {
            barcodeScanModal.show();
            barcodeFormMain.reset();
            barcodeFormModal.reset();
        });
    });

    installModalClickEvents(modals); // listen to click events on buttons with data-open-modal
    horizontalScroll.initialize();
}
