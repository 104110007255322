
function waitUntilVideoIsReady(videoElement) {
    return new Promise((resolve, reject) => {
        if(videoElement.videoWidth) {
            resolve();
        }
        videoElement.addEventListener('loadedmetadata', resolve, false);
        videoElement.addEventListener('error', reject, false);
    });
}

const canvasToPng = (function canvasToPng() {
    const fileReader = new FileReader();

    return (canvas) => {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if(!blob) {
                    return;
                }
                fileReader.onload = () => {
                    resolve(new Uint8ClampedArray(fileReader.result));
                }
                fileReader.readAsArrayBuffer(blob);
            }, 'image/png');
        });
    }
})();

function getRelativeBounds(parentBounds, childBounds) {
    return {
        x: childBounds.x - parentBounds.x,
        y: childBounds.y - parentBounds.y,
        width: childBounds.width,
        height: childBounds.height,
    }
}

function getSrcCoordinates(video, cropAreaBounds) {
    const w = video.videoWidth;
    const h = video.videoHeight;
    const viewableBounds = video.getBoundingClientRect();
    const cropAreaBoundsRelative = getRelativeBounds(viewableBounds, cropAreaBounds);

    if(w > h) {
        // desktop
        const sw = (w / h) * viewableBounds.height; // viewable bounds with corrected aspect ratio
        const scale = video.videoWidth / sw; // scale relative to native video width
        const sx = (video.videoWidth - (scale * viewableBounds.width)) * .5; // offset x of viewableBounds

        return {
            x: sx + cropAreaBoundsRelative.x * scale,
            y: cropAreaBoundsRelative.y * scale,
            width: cropAreaBoundsRelative.width * scale,
            height: cropAreaBoundsRelative.height * scale,
        }
    }
    else {
        const sh = (h / w) * viewableBounds.width; // viewable bounds with corrected aspect ratio
        const scale = video.videoHeight / sh; // scale relative to native video height
        const sy = (video.videoHeight - (scale * viewableBounds.height)) * .5; // offset y of viewableBounds

        return {
            x: cropAreaBoundsRelative.x * scale,
            y: sy + cropAreaBoundsRelative.y * scale,
            width: cropAreaBoundsRelative.width * scale,
            height: cropAreaBoundsRelative.height * scale,
        }
    }
}

export default function createFrameCapture(video, callback) {
    let busy;

    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d', { alpha: false });
    ctx.imageSmoothingEnabled = false;

    const videoReady = waitUntilVideoIsReady(video);

    const captureFrame = () => {
        if(busy) {
            return;
        }

        busy = true;

        videoReady
            .then(() => {
                const { x, y, width, height } = getSrcCoordinates(video, video.getBoundingClientRect());

                if(canvas.width !== width || canvas.height !== width) {
                    canvas.width = width;
                    canvas.height = height;
                }

                ctx.drawImage(video, x, y, width, height, 0, 0, width, height);

                return canvasToPng(canvas);
            })
            .then(buffer => {
                busy = false;
                callback(buffer, canvas.width, canvas.height);
            });
    };

    return captureFrame;
}
